/*
@media (min-width: 1200px) {
  .container{
    color:$text-color;
    max-width: 970px;
  }
}

.test-class {
  color: green;
}
*/